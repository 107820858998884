import { BlogPage } from '@/api/Api'

const items: BlogPage[] = [
  {
    name: "lamp",
    time: 1742553853864,
    slug: "lamp",
    previewText: "Whole my life I've been passioned about lamps. I remember my first table lamp which was begin bought as a present for a new year or birthday.",
    tags: [],
    contentUrl: require("!!file-loader!@/assets/blog/6-lamp.md")
  },
  {
    name: "lamp-pcb",
    time: 1742553853864,
    slug: "lamp-pcb",
    previewText: "I hacked an led lamp. Normal lamp looks like this:",
    tags: [],
    contentUrl: require("!!file-loader!@/assets/blog/7-lamp-pcb.md")
  },
  {
    name: "ethanol-torch",
    time: 1742553853864,
    slug: "ethanol-torch",
    previewText: "The end goal is to make a model rocket engine with deep throttle capability. Now-days all modern and popular rocket companies use this trick with retro propulsion. Without deep throttle it's impossible.",
    tags: [],
    contentUrl: require("!!file-loader!@/assets/blog/8-ethanol-torch.md")
  },
  {
    name: "rocket-motor",
    time: 1742553853864,
    slug: "rocket-motor",
    previewText: "My journey with rocket motors fabrication started from classic sugar motor. I made A rocket motor from aluminum tube and washers. A drilled couple of holes and made a this small unusable motor.",
    tags: [],
    contentUrl: require("!!file-loader!@/assets/blog/9-rocket-motor.md")
  },
  {
    name: "gfx",
    time: 1742553853863,
    slug: "gfx",
    previewText: "Particles really make difference",
    tags: [],
    contentUrl: require("!!file-loader!@/assets/blog/4-gfx.md")
  },
  {
    name: "ci-cd",
    time: 1742553853863,
    slug: "ci-cd",
    previewText: "Today I finally finish first build via automated deploy. I still need to implement deploy to, but it already cool ",
    tags: [],
    contentUrl: require("!!file-loader!@/assets/blog/5-ci-cd.md")
  },
  {
    name: "game",
    time: 1742553853862,
    slug: "game",
    previewText: "I've been making a game for last two years. I changed game engine from unity to godot. And I was naive thinking that c# works good in it. I have multiple problems with it not event speaking that documentation and example are less common as for gdscript.",
    tags: [],
    contentUrl: require("!!file-loader!@/assets/blog/13-game.md")
  },
  {
    name: "update-shop-screenshots",
    time: 1742553853862,
    slug: "update-shop-screenshots",
    previewText: "I've updated screenshots on store listing. New one looks a bit cooler I suppose.",
    tags: [],
    contentUrl: require("!!file-loader!@/assets/blog/14-update-shop-screenshots.md")
  },
  {
    name: "the-only-way-to-found-my-game",
    time: 1742553853862,
    slug: "the-only-way-to-found-my-game",
    previewText: "So far this is the only way to found my game in the store =\\",
    tags: [],
    contentUrl: require("!!file-loader!@/assets/blog/15-the-only-way-to-found-my-game.md")
  },
  {
    name: "session-time",
    time: 1742553853862,
    slug: "session-time",
    previewText: "I've added logging of session time. In order to do so I have to fix some problems with handling disconnect of client. I'm not sure I fixed all cases. ",
    tags: [],
    contentUrl: require("!!file-loader!@/assets/blog/16-session-time.md")
  },
  {
    name: "game-can-be-found-by-its-name",
    time: 1742553853862,
    slug: "game-can-be-found-by-its-name",
    previewText: "Looks like now it's possible to find the game by it's name. I'm so excited about it",
    tags: [],
    contentUrl: require("!!file-loader!@/assets/blog/17-game-can-be-found-by-its-name.md")
  },
  {
    name: "game-engine",
    time: 1742553853862,
    slug: "game-engine",
    previewText: "tldr: I chose Godot.",
    tags: [],
    contentUrl: require("!!file-loader!@/assets/blog/2-game-engine.md")
  },
  {
    name: "monetization",
    time: 1742553853862,
    slug: "monetization",
    previewText: "I din't want to run ads and I need a lot of users so paid model wasn't on my radar this time. Free to play. I know that this is an important aspect of a game so I made continuos decision about it and it should affect game mechanics.",
    tags: [],
    contentUrl: require("!!file-loader!@/assets/blog/3-monetization.md")
  },
  {
    name: "strategy-and-motivation",
    time: 1742553853861,
    slug: "strategy-and-motivation",
    previewText: "Hello friend, I want to share my thoughts about motivation and strategy, about my decision making process in context of developer of product. First of all is an essential thinking. Filter every move and action by apply principle essentialism.",
    tags: [],
    contentUrl: require("!!file-loader!@/assets/blog/1-strategy-and-motivation.md")
  },
  {
    name: "nozzles",
    time: 1742553853861,
    slug: "nozzles",
    previewText: "",
    tags: [],
    contentUrl: require("!!file-loader!@/assets/blog/10-nozzles.md")
  },
  {
    name: "thermal-sun-electric",
    time: 1742553853861,
    slug: "thermal-sun-electric",
    previewText: "I was wounder at which scale you can harvest sun energy efficiently by heating water and spin turbine.",
    tags: [],
    contentUrl: require("!!file-loader!@/assets/blog/11-thermal-sun-electric.md")
  },
  {
    name: "quote",
    time: 1742553853861,
    slug: "quote",
    previewText: "\"I'm sure some of those Russian tanks that are laying in the gutter of the Ukrainians roads are there because of some of the weapons that have been provided to Ukrainian forces over the last period.\" John Sawers",
    tags: [],
    contentUrl: require("!!file-loader!@/assets/blog/12-quote.md")
  }
]
export default items